.Main__Card {    
    padding: 0.3rem 0.3rem;
    background-color: #efefef;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-family: sans-serif;
    max-width:1000px;
    display:flex;
    margin:auto;
} 
    
.Content__Card {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 250px;
    border: 1px solid #979797;
    border-radius: 8px;
    color: #222;
} 
    
.Title {
    align-items: center;
    justify-content: center;
    text-align: center;
} 
    
.Subtitle {    
    font-size: 15px;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
} 
    
    
.SybilInput__Box{    
    margin: auto;
    gap: 1rem;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: stretch;
}
    
.Button__Check {    
    padding: 0.5rem 0.8rem;
    border-radius: 5px;
    transition: 0.2s ease-in-out;
    color: #fff;
    background: #007bff; 
    border-color: #007bff; 
    cursor:pointer;
    font-weight:bold;
    font-size:1rem;
} 
    
    
.Button__Check:hover{
    color: #e3e9f1;
    background-color: #0062cc;
    border-color: #0062cc;
} 

.Results{
    text-align:center;
    margin-top: 2 rem;
    font-size:16px;
} 
    
.InputBox {
    padding: 0.5rem 0.8rem;
    border-radius: 8px;
    width: 300px;
    border-color: #ab9a9a;
}


/* Extra Small Devices (Phones, <576px) */
@media (max-width: 575.98px) {
    /* Your CSS rules for extra small devices */
    .Button__Check {
        width: 100% !important;
    }
    
    .InputBox{
        width: 250px;
        
    }

    .SybilInput__Box{
        flex-direction:column;
    }

    .Subtitle{
        font-size: 18px;
    }

    .Title{
        font-size: 24px;
    }
  }
  
  /* Small Devices (Tablets, ≥576px) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    /* Your CSS rules for small devices */
  }
  
  /* Medium Devices (Desktops, ≥768px) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    /* Your CSS rules for medium devices */
  }
  
  /* Large Devices (Desktops, ≥992px) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    /* Your CSS rules for large devices */
  }
  
  /* Extra Large Devices (Large Desktops, ≥1200px) */
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    /* Your CSS rules for extra large devices */
  }
  
  /* Extra Extra Large Devices (Extra Large Desktops, ≥1400px) */
  @media (min-width: 1400px) {
    /* Your CSS rules for extra extra large devices */
  }
  
